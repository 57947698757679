<template>
  <v-app class="app-body">
    <navbar/>
    <v-main class="main-sec">
      <router-view></router-view>
    </v-main>
    <footerBar class="main-sec" />
  </v-app>
</template>

<script>
import navbar from './components/navbar.vue'
import footerBar from './components/footer.vue'
import { profile, headersNoAuth, headers, homeTrips } from './links'
// import { db } from './firebase.js'

export default {
  name: 'App',
  components: {
    navbar,
    footerBar
  },
  data () {
    return {
      topWindow: 'hello',
      users: [],
      version: '2.1'
    }
  },
  mounted () {
    if (this.$store.state.version !== this.version) {
      this.$store.dispatch('removeAirportsData')
      this.$store.dispatch('removeDatesData')
      this.$store.dispatch('removePassengersData')
      this.$store.dispatch('setPreferredAirline', null)
      this.$store.dispatch('setVersion', this.version)
    }
    this.$store.dispatch('setUserMails', this.users)
    if (this.$cookies.isKey('userToken')) {
      this.$http.get(profile, { headers: headers(this.$cookies.get('userToken')) }).then(response => {
        if (response.body.status) this.$store.dispatch('setUser', response.body.data)
        else {
          this.$store.dispatch('removeUser')
          this.$cookies.remove('userToken')
          localStorage.removeItem('userId')
          localStorage.removeItem('token')
        }
      })
    }
    this.$http.get(homeTrips, { headers: headersNoAuth() }).then(response => {
      this.$store.dispatch('setHomeTrips', response.body.data)
    })
  },
  created () {
    // const link = document.createElement('link')
    // link.type = 'image/png'
    // link.rel = 'shortcut icon'
    // link.href = '/src/assets/icon.png'
    // document.getElementsByTagName('head')[0].appendChild(link)
    this.$http.interceptors.push((request, next) => {
      next(response => {
        if (response.status === 401) {
          this.$store.dispatch('removeUser')
          this.$cookies.remove('userToken')
          localStorage.removeItem('userId')
          localStorage.removeItem('token')
          this.$router.push({ name: 'login' })
        }
      })
    })
    window.addEventListener('beforeunload', (e) => {
      e.preventDefault()
      this.$store.dispatch('removeCountryCode')
      this.$store.dispatch('removeGuestData')
    })
  }
}
</script>

<style>
  @import url('https://fonts.googleapis.com/css2?family=Playball&display=swap');
  .v-application {
    max-width: 100vw;
  }
  .font-header {
    font-family: 'Playball', cursive;
    font-size: 31px;
  }
</style>
