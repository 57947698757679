import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import VueResource from 'vue-resource'
import titleMixin from './mixins/titleMixin'
import VueCookies from 'vue-cookies'
import * as VueGoogleMaps from 'vue2-google-maps'
import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css'
import { currencies } from './links.js'

Vue.prototype.window = window

Vue.config.productionTip = false

const url = currencies
fetch(url).then(response => response.json()).then(response => {
  if (response.status) {
    store.dispatch('setCurrencies', response.data)
  }
  if (process.env.VUE_APP_DEFAULT_CURRENCY) {
    store.dispatch('setCurrency', response.data.find(item => item.code === process.env.VUE_APP_DEFAULT_CURRENCY))
  }
})

Vue.filter('changeCurrency', function (price) {
  const defaultRate = store.state.currencies.find(item => item.code === process.env.VUE_APP_DEFAULT_CURRENCY) || 1
  const finalPrice = (price / defaultRate.rate) * store.state.currency.rate
  return store.state.currency.code + ' ' + finalPrice.toFixed(2)
})

Vue.filter('formatDate', function (date) {
  date.replace(/-/g, '/')
  return new Date(date).toDateString()
})

Vue.component('date-picker', DatePicker)

Vue.use(VueResource)
Vue.mixin(titleMixin)
Vue.use(VueCookies)
Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyCMpLmI6ZbrtqkEA_hIP7aWCJvRsCLz11c',
    libraries: 'places' // necessary for places input
  }
})

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')

// function sendExceptionLog (msg, trace, severity) {
//   const payload = {
//     message: msg,
//     url: window.location.href,
//     applicationType: 'web',
//     severity: severity,
//     stacktrace: trace,
//     username: null
//   }
//   const url = appLog
//   const params = {
//     headers: headersNoAuth(),
//     body: JSON.stringify(payload),
//     method: 'POST'
//   }
//   fetch(url, params)
// }

// if (process.env.NODE_ENV !== 'development') {
//   Vue.config.errorHandler = function (err, vm, info) {
//     sendExceptionLog(err, info, 'error')
//   }
//   Vue.config.warnHandler = function (msg, vm, trace) {
//     sendExceptionLog(msg, trace, 'warning')
//   }
// }
