import Vue from 'vue'
import Vuetify from 'vuetify/lib'

Vue.use(Vuetify, {
  options: {
    customProperties: true
  }
})

export default new Vuetify({
  theme: {
    themes: {
      light: {
        greyText: '#707070',
        secondary: process.env.VUE_APP_DEFAULT_SECONDARY_COLOR,
        primary: process.env.VUE_APP_DEFAULT_PRIMARY_COLOR,
        textColor: '#333333'
      }
    }
  }
})
