import Vue from 'vue'
import VueRouter from 'vue-router'

import home from '../views/new-home.vue'
import empty from '../components/404.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: home
  },
  {
    path: '/flights',
    name: 'flights',
    component: () => import(/* webpackChunkName: "flights" */ '../views/flights.vue')
  },
  {
    path: '/flightResults',
    name: 'flightResults',
    component: () => import(/* webpackChunkName: "flights results" */ '../views/flightResults.vue')
  },
  {
    path: '/book/:id/:search/:relatedFlightId',
    name: 'book',
    component: () => import(/* webpackChunkName: "flights book" */ '../views/book.vue')
  },
  {
    path: '/verify',
    name: 'verify',
    component: () => import(/* webpackChunkName: "verify" */ '../views/verify.vue')
  },
  {
    path: '/login',
    name: 'login',
    component: () => import(/* webpackChunkName: "login" */ '../views/login.vue')
  },
  {
    path: '/resetPassword',
    name: 'resetPassword',
    component: () => import(/* webpackChunkName: "reset" */ '../views/resetPassword.vue')
  },
  {
    path: '/profile',
    name: 'profile',
    component: () => import(/* webpackChunkName: "profile" */ '../views/profile.vue')
  },
  {
    path: '/hotelsResults',
    name: 'hotelsResults',
    component: () => import(/* webpackChunkName: "hotel results" */ '../views/hotelsResults.vue')
  },
  {
    path: '/hotels',
    name: 'hotels',
    component: () => import(/* webpackChunkName: "hotels" */ '../views/hotels.vue')
  },
  {
    path: '/hotel/:sessionId/:hotelIndex/:hotelCode',
    name: 'oneHotel',
    component: () => import(/* webpackChunkName: "hotel details" */ '../views/oneHotel.vue')
  },
  {
    path: '/bookhotel',
    name: 'hotelBook',
    component: () => import(/* webpackChunkName: "hotel book" */ '../views/hotelBook.vue')
  },
  {
    path: '/cars',
    name: 'cars',
    component: () => import(/* webpackChunkName: "cars" */ '../views/cars.vue')
  },
  {
    path: '/carsResults',
    name: 'carsResults',
    component: () => import(/* webpackChunkName: "cars results" */ '../views/carsResults.vue')
  },
  {
    path: '/carBook/:searchId/:carId',
    name: 'carsBook',
    component: () => import('../views/carBook.vue')
  },
  {
    path: '/trips',
    name: 'trips',
    component: () => import(/* webpackChunkName: "trips" */ '../views/trips.vue')
  },
  {
    path: '/trip',
    name: 'trip',
    component: () => import(/* webpackChunkName: "trip" */ '../views/trip.vue')
  },
  {
    path: '/register',
    name: 'register',
    component: () => import(/* webpackChunkName: "register" */ '../views/register.vue')
  },
  {
    path: '/about',
    name: 'about',
    component: () => import(/* webpackChunkName: "about" */ '../views/about.vue')
  },
  {
    path: '/rewards',
    name: 'rewards',
    component: () => import(/* webpackChunkName: "rewards" */ '../views/rewards.vue')
  },
  {
    path: '/terms',
    name: 'terms',
    component: () => import(/* webpackChunkName: "terms" */ '../views/terms.vue')
  },
  {
    path: '/privacy',
    name: 'privacy',
    component: () => import(/* webpackChunkName: "policies" */ '../views/privacy.vue')
  },
  {
    path: '/help',
    name: 'help',
    component: () => import(/* webpackChunkName: "help" */ '../views/help.vue')
  },
  {
    path: '*',
    component: empty
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})
// router.beforeEach((to, from, next) => {
//   if (process.env.VUE_APP_MODE === 'adam' || process.env.VUE_APP_MODE === 'adamDev') {
//     if (to.name !== 'login' && to.name !== 'register' && to.name !== 'verify' && to.name !== 'resetPassword' && to.name !== 'terms' && to.name !== 'privacy' && to.name !== 'approve' && !localStorage.getItem('token')) next({ name: 'login' })
//     else next()
//   } else if (process.env.VUE_APP_MODE === 'ats' || process.env.VUE_APP_MODE === 'atsDev') {
//     if (to.name !== 'login' && to.name !== 'resetPassword' && to.name !== 'approve' && !localStorage.getItem('token')) next({ name: 'login' })
//     else next()
//   } else next()
// })
export default router
