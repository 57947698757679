<template>
  <div>
    <v-app-bar
    dark
    color="white"
    elevation="0"
    :prominent="$vuetify.breakpoint.smAndUp"
    scroll-off-screen
    style="max-width: 100vw;"
    >
      <v-container :fluid="$vuetify.breakpoint.mdAndDown">
        <v-row align="center">
            <v-btn icon @click="drawer = !drawer" class="d-flex d-md-none mt-3">
              <v-icon large color="secondary">mdi-menu</v-icon>
            </v-btn>

            <v-col cols="2" class="pb-4">
              <router-link style="text-decoration: none;" to="/">
                <!-- <v-img src="../assets/logo.png" class="ma-1" alt="logo" max-width="50"></v-img> -->
                <img src="../assets/logo.png" class="ma-1" alt="logo" style="max-height: 50px; min-width: 70px;">
              </router-link>
            </v-col>

            <div class="d-flex">
              <v-btn v-for="tab in tabs" :key="tab.title" exact :to="{ name: tab.route} " :text="$route.name !== tab.title" :elevation="$route.name == tab.title ? 2 : 0" :tile="$route.name == tab.title" :color="$route.name == tab.title ? 'white' : 'secondary'" :height="85" :large="$vuetify.breakpoint.lgAndUp" class="d-none d-md-flex" >
                  <v-icon color="primary">{{tab.icon}}</v-icon>
                  <span class="body-1 ml-2 font-weight-medium primary--text">{{tab.title}}</span>
              </v-btn>
            </div>

            <v-spacer></v-spacer>

            <v-menu offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                class="py-2 d-none d-md-block"
                color="secondary"
                dark
                v-bind="attrs"
                outlined
                large
                v-on="on"
                >
                  <v-icon left>mdi-cash</v-icon> {{currency.code}}
                </v-btn>
              </template>
              <v-list>
                <v-list-item
                v-for="(item, i) in currencies"
                :key="i"
                @click="$store.dispatch('setCurrency', item)"
                >
                  <v-list-item-title>{{ item.code }}</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>

            <router-link class="text-decoration-none d-none d-sm-flex" :to="{ name: 'login'} " v-if="!$cookies.isKey('userToken')">
              <v-btn
              elevation="0"
              :large="$vuetify.breakpoint.lgAndUp"
              :small="$vuetify.breakpoint.mdAndDown"
              color="secondary"
              height="47"
              class="px-10 ml-1"
              >
                <span>Login</span>
                <v-icon right>mdi-login-variant</v-icon>
              </v-btn>
            </router-link>

            <v-menu offset-y v-else class="d-none d-sm-flex">
              <template v-slot:activator="{ on }">
                <v-btn
                color="white --text"
                text
                v-on="on"
                >
                  <v-avatar color="secondary" class="mr-2" size="40">
                    <span class="white--text body-1">
                      {{user.name.charAt(0).toUpperCase()}}
                    </span>
                  </v-avatar>
                  <span class="d-none d-sm-flex secondary--text">{{user.name.substr(0, user.name.indexOf(' '))}}</span>
                </v-btn>
              </template>
              <v-list>
                <v-list-item link exact :to="{ name: 'profile' }">
                  <v-list-item-icon><v-icon>mdi-account</v-icon></v-list-item-icon>
                  <v-list-item-title>Profile</v-list-item-title>
                </v-list-item>
                <v-list-item @click="logout">
                  <v-list-item-icon><v-icon>mdi-logout</v-icon></v-list-item-icon>
                  <v-list-item-title>Logout</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>

        </v-row>
      </v-container>

      <template v-slot:extension v-if="$vuetify.breakpoint.smAndUp">
        <div
        class="subNav d-none d-sm-flex"
        :style="`background-color: ${primaryColor}; width: 100%;`"
        v-if="$store.state.mode !== 'ats' && $store.state.mode !== 'atsDev' && $store.state.mode !== 'adam' && $store.state.mode !== 'adamDev'"
        >
          <v-container class="py-0">
            <v-btn
            v-for="item in links"
            :key="item.route"
            class="rounded-0 rounded-br-lg rounded-bl-lg mx-2"
            color="white primary--text"
            @click="goTo(item.route)"
            :height="48"
            :elevation="$route.name == item.route ? 2 : 0"
            :text="$route.name !== item.route"
            :x-large="$vuetify.breakpoint.lgAndUp"
            :x-small="$vuetify.breakpoint.smAndDown"
            >
              <span class="body-2">{{item.title}}</span>
            </v-btn>
          </v-container>
        </div>
      </template>

    </v-app-bar>

    <v-navigation-drawer
    v-model="drawer"
    absolute
    color="white"
    dark
    temporary
    >
      <v-list
      nav
      dense
      >
        <v-list-item-group
        v-model="group"
        active-class="secondary--text text-accent-4"
        >
          <v-list-item link exact to="/">
            <v-img src="../assets/logo.png" alt="fare33 logo" max-width="50px"></v-img>
          </v-list-item>

          <v-list-item two-line link exact :to="{ name: 'login' }" v-if="!$cookies.isKey('userToken')">
            <v-list-item-avatar>
              <v-icon color="secondary">mdi-account-circle</v-icon>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title class="secondary--text">Login / Register</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item two-line link exact :to="{ name: 'profile' }" v-else>
            <v-list-item-avatar v-if="user !== null">
              <v-avatar color="secondary" class="text-center" size="32">
                <span class="white--text body-1">
                  {{user.name.charAt(0).toUpperCase()}}
                </span>
              </v-avatar>
            </v-list-item-avatar>

            <v-list-item-content>
              <v-list-item-title v-cloak class="secondary--text">{{user.name}}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item
          active-class="active-route"
          link exact
          :to="{ name: item.route }" v-for="item in tabs" :key="item.title"
          >
            <v-list-item-title :class="$route.name === item.title ? 'white--text' : 'secondary--text'">
              <v-icon small :color="$route.name === item.title ? 'white' : 'secondary'">{{item.icon}}</v-icon>
              {{item.title}}
            </v-list-item-title>
          </v-list-item>
          <v-list-item
          active-class="active-route"
          >
            <v-btn @click="goTo('about')" text x-small color="secondary">About us</v-btn>
          </v-list-item>
          <v-list-item
          active-class="active-route"
          @click="goTo('rewards')"
          >
            <v-btn text x-small color="secondary">Rewards</v-btn>
          </v-list-item>

          <v-list-item
          active-class="active-route"
          @click="goTo('trips')"
          >
            <v-btn text x-small color="secondary">Trips</v-btn>
          </v-list-item>

          <v-list-item>
            <v-menu offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                class="py-2"
                color="secondary"
                dark
                v-bind="attrs"
                outlined
                large
                v-on="on"
                >
                  <v-icon left>mdi-cash</v-icon> {{currency.code}}
                </v-btn>
              </template>
              <v-list>
                <v-list-item
                v-for="(item, i) in currencies"
                :key="i"
                @click="$store.dispatch('setCurrency', item)"
                >
                  <v-list-item-title>{{ item.code }}</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>
  </div>
</template>

<script>
import { logout, headers } from '../links'
import { mapState } from 'vuex'

export default {
  data () {
    return {
      drawer: false,
      group: null,
      tabs: [
        { title: 'flights', route: 'flights', icon: 'mdi-airplane' },
        { title: 'hotels', route: 'hotels', icon: 'mdi-domain' },
        { title: 'cars', route: 'cars', icon: 'mdi-car' }
      ],
      links: [
        { title: 'home', route: 'Home' },
        { title: 'trips', route: 'trips' },
        { title: 'about us', route: 'about' },
        { title: 'rewards', route: 'rewards' }
      ],
      user: null
    }
  },
  computed: {
    userStore () {
      return this.$store.state.user
    },
    ...mapState(['currency', 'currencies']),
    primaryColor () {
      return process.env.VUE_APP_DEFAULT_PRIMARY_COLOR
    }
  },
  watch: {
    userStore (newVal) {
      this.user = newVal
    },
    group () {
      this.drawer = false
    }
  },
  methods: {
    goTo (link) {
      this.$router.push({ name: link })
    },
    logout () {
      this.$http.post(logout, null, { headers: headers(this.$cookies.get('userToken')) }).then(() => {
        this.$store.dispatch('removeUser')
        this.$cookies.remove('userToken')
        localStorage.removeItem('userId')
        localStorage.removeItem('token')
        this.$router.push({ name: 'login' })
      })
    }
  },
  created () {
    this.user = this.$store.state.user
  }
}
</script>

<style>
  [v-cloak] { display: none; }
  .subNav {
    overflow-x: auto;
  }
  .v-toolbar {
    height: 143px !important;
  }
  .v-toolbar__content {
    height: 95px !important;
  }
  .v-toolbar__extension {
    background-color: #325AAF;
    padding: 0 !important;
  }
  .active-route {
    background-color: #E28134;
    border-radius: 5PX;
    height: 58px;
  }
  .active-route-blue {
    background-color: #150958;
    border-radius: 5PX;
    height: 58px;
  }
</style>
