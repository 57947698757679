<template>
  <div>
    <v-row>
      <v-col cols="12" md="4" class="py-0 pr-4 mb-5">
        <v-card
        elevation="0"
        tile
        class="rounded-lg"
        >
          <v-img
          src="../static/trip.png"
          class="white--text align-center px-8"
          gradient="to top right, rgba(50,90,175,.50), rgba(50,90,175,.50)"
          height="472.5px"
          >
            <v-card-title class="text-h4 font-weight-black text-break pl-0">Let’s Explore The World</v-card-title>
            <p class="white--text">Book the favorite place all over the world And start exploring</p>
            <v-btn class="white secondary--text rounded-lg py-7" @click="$router.push({ path: '/trips' })" x-large elevation="0">Know More</v-btn>
          </v-img>
        </v-card>
      </v-col>
      <v-col cols="12" md="8" class="pa-0 pt-2">
        <v-row>
          <v-col class="px-1 pb-0 pt-1" cols="12" sm="6" v-for="(trip, i) in trips" :key="trip.id">
            <v-card
            v-if="i < 4"
            tile
            @click="goTo(trip.link)"
            elevation="0"
            height="235px"
            >
              <v-img
              :src="trip.image"
              class="white--text align-end"
              height="235px"
              >
                <v-card-title class="headline font-weight-medium" v-text="trip.title"></v-card-title>
              </v-img>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { homeTrips, headersNoAuth } from '../links'

export default {
  data () {
    return {
      trips: [],
      model: null
    }
  },
  methods: {
    goTo (trip) {
      if (trip) {
        this.$store.dispatch('setTrip', trip)
        this.$router.push({ name: 'trip' })
      }
    }
  },
  created () {
    if (this.$store.state.homeTrips.length === 0) {
      this.$http.get(homeTrips, { headers: headersNoAuth() }).then(response => {
        this.trips = response.body.data
      })
    } else this.trips = this.$store.state.homeTrips
  }
}
</script>

<style>
  .featured-trips-title {
    /* background-image: linear-gradient(to top, rgba(50, 0, 255, 0.7), rgb(251 164 5 / 53%)); */
    background-image: linear-gradient(to top, rgba(50, 0, 255, 0.7), rgb(0 0 0 / 53%));
  }
</style>
