<template>
  <div>
    <v-footer
    padless
    elevation="3"
    >
      <v-card
      tile
      width="100%"
      color="primary"
      >
        <v-card-text class="footer">
          <v-container fluid>
            <v-row justify="space-between">
              <v-col cols="12" sm="6" md="3">
                <img src="../assets/logo.png" style="max-height: 50px; min-width: 70px;">
                <p class="body-2 mt-5 pr-5 white--text">
                  {{description}}
                </p>
                <div v-if="social.length">
                  <strong class="white--text">Follow us: </strong>
                  <a v-for="item in social" class="mx-3 social-links" :key="item.icon" :href="item.link" target="_blank">
                    <v-icon color="white">{{item.icon}}</v-icon>
                  </a>
                </div>
              </v-col>
              <v-col cols="6" sm="6" md="3">
                <span class="white--text font-weight-bold" style="font-size: 20px;">Easy Access</span>
                <hr class="divider">
                <ul class="links-list">
                  <li>
                    <router-link to="/about">About</router-link>
                  </li>
                  <li>
                    <router-link to="/flights">Flights</router-link>
                  </li>
                  <li>
                    <router-link to="/hotels">Hotels</router-link>
                  </li>
                  <li>
                    <router-link to="/cars">Cars</router-link>
                  </li>
                  <li>
                    <router-link to="/help">Customer Service</router-link>
                  </li>
                </ul>
              </v-col>
              <v-col cols="6" sm="6" md="3">
                <span class="white--text font-weight-bold" style="font-size: 20px;">Legal & Rewards</span>
                <hr class="divider">
                <ul class="links-list">
                  <li>
                    <router-link to="/terms">Terms & conditions</router-link>
                  </li>
                  <li>
                    <router-link to="/privacy">Privacy Policy</router-link>
                  </li>
                  <li>
                    <router-link to="/rewards">Rewards</router-link>
                  </li>
                </ul>
              </v-col>
              <v-col cols="12" sm="6" md="3">
                <span class="white--text font-weight-bold" style="font-size: 20px;">Contacts</span>
                <hr class="divider">
                <div class="my-5 text-left" v-for="item in contacts" :key="item.link">
                  <a class="white--text text-decoration-none" v-if="item.type === 'phone'" target="_blank" :href="`tel:${item.link}`"><v-icon color="white">{{item.icon}}</v-icon> {{item.link}}</a>
                  <a class="white--text text-decoration-none" v-else-if="item.type === 'mail'" target="_blank" :href="`mailto:${item.link}`"><v-icon color="white">{{item.icon}}</v-icon> {{item.link}}</a>
                  <span v-else class="white--text caption"> <v-icon color="white">{{item.icon}}</v-icon> {{item.link}}</span>
                </div>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <hr style="background-color: grey; border: none; height: 0.5px;">
          <p class="text-center bod-2 white textColor--text text-right mb-0 py-3">
            &copy; {{ new Date().getFullYear() }} All rights reserved. {{siteName}}
          </p>
      </v-card>
    </v-footer>
  </div>
</template>

<script>
export default {
  data () {
    return {
      dialog: false,
      dialog1: false,
      title: ''
    }
  },
  computed: {
    siteName () {
      return process.env.VUE_APP_DEFAULT_TITLE
    },
    description () {
      return process.env.VUE_APP_DEFAULT_DESC
    },
    social () {
      return JSON.parse(process.env.VUE_APP_DEFAULT_APP_SOCIAL_LINKS)
    },
    contacts () {
      return JSON.parse(process.env.VUE_APP_DEFAULT_APP_CONTACTS)
    }
  },
  methods: {
    goTo (page) {
      this.$router.push({ name: page })
    }
  }
}
</script>

<style scoped>
  .footer {
    background-image: url('../static/map.png');
    background-size: contain;
    background-position: center;
  }
  .download-link {
    text-decoration: none;
    font-weight: 700;
    font-size: 1rem;
  }
  .details {
    font-size: 11px;
    margin-right: 15px;
  }
  h3 {
    font-size: 15px;
  }
  ul {
    list-style: none;
  }
  .social-links {
    text-decoration: none;
  }
  .list-items {
    text-decoration: none;
    color: grey;
    font-size: 12px;
    transition: .2s linear;
  }
  .list-items:hover {
    color: white;
    font-weight: bold;
    cursor: pointer;
  }
  .subscripe {
    min-height: 220px;
    position: relative;
  }
  .btn {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
  }
  .divider {
    background-color:#fff;
    width: 50px;
    height: 3px;
    margin-top: 5px;
    border: none;
  }
  .privacy-link {
    color: grey;
    text-decoration: none;
  }
  .privacy-link:hover {
    cursor: pointer;
    color: white;
  }
  .adam-footer {
    border-top: 3px solid #FDB71A !important;
  }
  .social-adam-cont {
    display: flex;
    margin-top: 5px;
  }
  .social-adam {
    background-color: #FDB71A;
    height: 30px;
    width: 30px;
    margin-right: 5px;
    text-align: center;
  }
  .social-adam:hover {
    background-color: #f1ad1a;
  }
  .social-adam span {
    color: #1a3057;
    font-size: 1.3rem;
    font-weight: 900;
  }
  .social-adam-cont a {
    text-decoration: none;
  }
  @media screen and (max-width: 960px) {
    .brand {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
    }
    .details {
      margin-top: 50px;
      text-align: center;
    }
    ul {
      text-align: center;
      margin: 20px;
    }
  }
  @media screen and (max-width: 526px) {
    ul {
     list-style: disc;
    }
  }
  .links-list {
    list-style: none;
    margin-top: 20px;
    padding-left: 0px;
  }
  .links-list li {
    margin: 10px 0;
    text-align: left;
  }
  .links-list li a {
    color: white;
    text-decoration: none;
    font-weight: bold;
    font-size: 18px;
  }
</style>
