<template>
  <div>
    <v-snackbar v-model="snackbar" :color="color" top :timeout="5000">
      {{text}}
      <v-btn icon @click="snackbar = false"><v-icon color="white">mdi-close</v-icon></v-btn>
    </v-snackbar>
    <search />
    <v-container class="my-15 home-cont">
      <!-- trip ideas -->
      <p class="text-center mt-15 mb-0 font-header secondary--text">Take Yourself</p>
      <h2 class="text-center font-weight-medium section-title primary--text font-weight-regular text-h3 mb-2">Trips Ideas</h2>
      <featured class="mx-5"></featured>
    </v-container>
    <div class="mobile-cont" v-if="appAndroidURL || appIOSURL">
      <v-container>
        <v-row class="my-15 pt-15" justify="space-between">
          <v-col cols="12" md="6">
            <h2 class="text-center font-weight-medium section-title primary--text font-weight-regular text-h3 mb-2">Get our application</h2>
            <p class="text-h6 textColor--text text-center">Download the {{siteName}} application now and freely book flights, hotels and more with the ease of your mobile.</p>
            <div :class="$vuetify.breakpoint.mdAndUp ? 'mx-auto' : 'mx-auto'" style="width: fit-content;">
              <a v-if="appAndroidURL" class="mr-5" target="_blank" href="https://play.google.com/store/apps/details?id=com.fare33.fare33">
                <img src="../static/play.png" width="200" height="50" alt="DoWNLOAD ON GOOGLE PLAY">
              </a>
              <a v-if="appIOSURL" target="_blank" href="https://apps.apple.com/eg/app/fare33/id1596648004">
                <img src="../static/app.png" width="200" height="50" alt="DoWNLOAD ON APP STORE">
              </a>
          </div>
          </v-col>
          <v-col cols="12" md="6">
            <v-img class="mx-auto" src="../assets/phone.png" max-width="50%" contain alt="Fare33 Mobile App"></v-img>
          </v-col>
        </v-row>
      </v-container>
    </div>
  </div>
</template>

<script>
// import { searchFlights, headersNoAuth } from '../links'
import featured from '../components/featured trips.vue'
import goTo from 'vuetify/es5/services/goto'
import search from '../components/searchScreen.vue'

export default {
  title: ' ',
  name: 'Home',
  components: {
    search,
    featured
  },
  data () {
    return {
      el: 1,
      length: 6,
      promotions: [],
      promotionIndex: null,
      snackbar: false,
      color: '',
      text: '',
      overlay: false
    }
  },
  computed: {
    siteName () { return process.env.VUE_APP_DEFAULT_TITLE },
    appAndroidURL () { return process.env.VUE_APP_DEFAULT_APP_URL_ANDROID },
    appIOSURL () { return process.env.VUE_APP_DEFAULT_APP_URL_IOS }
  },
  methods: {
    onScroll () {
      if (window.top.scrollY === 600) {
        document.getElementById('trips').classList.add('scale-in-center')
      }
    },
    goTo (link) {
      window.open(link, '_blank')
    },
    savePromotion (flight, n) {
      this.promotionIndex = n
      this.$store.dispatch('setAirports', { data: flight.origin, type: 'departure1' })
      this.$store.dispatch('setAirports', { data: flight.destination, type: 'arrival1' })
      this.$store.dispatch('setDates', { data: flight.departureDate, type: 'departure1' })
      this.$store.dispatch('setDates', { data: flight.returnDate, type: 'arrival' })
      this.$store.dispatch('setType', 'round')
      const string = `tripType=round&origin=${flight.origin.Code}&originType=${flight.origin.Type}&destination=${flight.destination.Code}&destinationType=${flight.destination.Type}&departureDate=${flight.departureDate}&arrivalDate=${flight.returnDate}&classType=Y&adults=1`
      this.overlay = true
      this.$store.dispatch('setFlightsTimeOut', false)
      if (this.$store.state.flightsSearchTimeOutFunction !== null) this.$store.dispatch('clearFlightsSearchTimeoutFunction')
      if (this.$store.state.flightsTimeOutFunction !== null) this.$store.dispatch('clearFlightsTimeoutFunction')
      this.$store.dispatch('removeFlightResults')
      this.$router.push({ name: 'flightResults', params: { term: string } })
    },
    getCols (size) {
      if (size === 'sm') {
        const cols = this.length === 1 ? 12 : 6
        return cols
      } else {
        let cols
        switch (this.length) {
          case 1:
            cols = 12
            break
          case 2:
            cols = 6
            break
          case 3:
            cols = 4
            break
          case 4:
            cols = 3
            break
          case 6:
            cols = 4
            break
        }
        return cols
      }
    },
    getThisDate (date) {
      const givenDate = new Date(date)
      const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
      const day = givenDate.getDate()
      return `${months[givenDate.getMonth()]} ${day}, ${givenDate.getFullYear()}`
    }
  },
  created () {
    window.scrollTo(0, 0)
    this.promotions = this.$store.state.flightPromotions
    this.length = this.promotions.length
    window.addEventListener('keydown', function (e) {
      if (e.keyCode === 34) {
        goTo(window.pageYOffset + window.innerHeight + 50)
      } else if (e.keyCode === 33) window.scrollBy(0, -100)
    })
  },
  mounted () {
    window.addEventListener('scroll', this.onScroll)
  },
  beforeDestroy () {
    window.removeEventListener('scroll', this.onScroll)
  }
}
</script>

<style>
  .section-title {
    position: relative;
    line-height: 80px !important;
  }
  .mobile-cont {
    background-image: url('../static/bg2.png');
    background-size: contain;
    background-position: right, center;
  }
  /* .section-title::after {
    content: '';
    position: absolute;
    width: 20%;
    height: 0.7px;
    background-color: sandybrown;
    left: 50%;
    top: 100%;
    transform: translateX(-50%);
  }
  .section-title__left::after {
    content: '';
    position: absolute;
    width: 50%;
    height: 0.7px;
    background-color: sandybrown;
    left: 30%;
    top: 100%;
    transform: translateX(-50%);
  } */
  /* .home-cont {
    background-image: url('../static/map.png');
    background-size: contain;
    background-repeat: no-repeat;
    background-position-y: center;
  } */
</style>
