<template>
    <v-form
    ref="form"
    v-model="valid"
    v-on:submit.prevent
    >
      <v-snackbar
      :color="color"
      :timeout="60000"
      v-model="alert"
      top
      id="snackbar"
      >
        <v-row>
          {{text}}
          <v-spacer></v-spacer>
          <v-icon color="white" @click="alert = false">mdi-close</v-icon>
        </v-row>
      </v-snackbar>
      <v-row no-gutters class="mt-5">
        <v-col cols="12" class="text-right mb-2">
          <v-menu
          v-model="menu"
          :close-on-content-click="false"
          :close-on-click="true"
          bottom
          transition="scale-transition"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
              color="primary"
              dark
              text
              v-bind="attrs"
              v-on="on"
              >
                {{label}}
                <v-icon right>mdi-chevron-down</v-icon>
              </v-btn>
            </template>
            <v-card max-height="500" color="white">
              <v-card-text style="max-height: 500px; overflow: auto;">
                <div class="inputs" v-for="n in roomsNum" :key="n">
                  <div class="rooms" v-if="n === 1">
                    <span class="label">Rooms</span>
                    <span class="minus" @click="decrease('rooms')"><v-icon color="white" small>mdi-minus</v-icon></span>
                    <span class="number" id="room">1</span>
                    <span class="plus" @click="increase('rooms')"><v-icon color="white" small>mdi-plus</v-icon></span>
                  </div>
                  <div v-else class="room-text">Room {{n}}</div>
                  <div class="adults">
                    <span class="label">Adults</span>
                    <span class="minus" @click="decrease('adults', n)"><v-icon  color="white" small>mdi-minus</v-icon></span>
                    <span class="number" :id="`adult${n}`">1</span>
                    <span class="plus" @click="increase('adults', n)"><v-icon  color="white" small>mdi-plus</v-icon></span>
                  </div>
                  <div class="children">
                    <span class="label">Children</span>
                    <span class="minus" @click="decrease('children', n)"><v-icon color="white" small>mdi-minus</v-icon></span>
                    <span class="number" :id="`child${n}`">0</span>
                    <span class="plus" @click="increase('children', n)"><v-icon color="white" small>mdi-plus</v-icon></span>
                  </div>
                  <div class="ages" :id="`ages${n}`">
                    <div class="age" v-if="n === 1">
                      <div class="age" v-for="i in childrenNumber1" :key="i">
                        <span class="minus" @click="decrease('age', n, i)"><v-icon color="white" small>mdi-minus</v-icon></span>
                        <span class="number" :id="`age${i}`">1</span>
                        <span class="plus" @click="increase('age', n, i)"><v-icon color="white" small>mdi-plus</v-icon></span>
                      </div>
                    </div>
                    <div class="age" v-if="n === 2">
                      <div class="age" v-for="i in childrenNumber2" :key="i">
                        <span class="minus" @click="decrease('age', n, i)"><v-icon color="white" small>mdi-minus</v-icon></span>
                        <span class="number" :id="`age${i + childrenNumber1}`">1</span>
                        <span class="plus" @click="increase('age', n, i)"><v-icon color="white" small>mdi-plus</v-icon></span>
                      </div>
                    </div>
                    <div class="age" v-if="n === 3">
                      <div class="age" v-for="i in childrenNumber3" :key="i">
                        <span class="minus" @click="decrease('age', n, i)"><v-icon color="white" small>mdi-minus</v-icon></span>
                        <span class="number" :id="`age${i + childrenNumber2 + childrenNumber1}`">1</span>
                        <span class="plus" @click="increase('age', n, i)"><v-icon color="white" small>mdi-plus</v-icon></span>
                      </div>
                    </div>
                    <div class="age" v-if="n === 4">
                      <div class="age" v-for="i in childrenNumber4" :key="i">
                        <span class="minus" @click="decrease('age', n, i)"><v-icon color="white" small>mdi-minus</v-icon></span>
                        <span class="number" :id="`age${i + childrenNumber3 + childrenNumber2 + childrenNumber1}`">1</span>
                        <span class="plus" @click="increase('age', n, i)"><v-icon color="white" small>mdi-plus</v-icon></span>
                      </div>
                    </div>
                    <div class="age" v-if="n === 5">
                      <div class="age" v-for="i in childrenNumber5" :key="i">
                        <span class="minus" @click="decrease('age', n, i)"><v-icon color="white" small>mdi-minus</v-icon></span>
                        <span class="number" :id="`age${i + childrenNumber4 + childrenNumber3 + childrenNumber2 + childrenNumber1}`">1</span>
                        <span class="plus" @click="increase('age', n, i)"><v-icon color="white" small>mdi-plus</v-icon></span>
                      </div>
                    </div>
                    <div class="age" v-if="n === 6">
                      <div class="age" v-for="i in childrenNumber6" :key="i">
                        <span class="minus" @click="decrease('age', n, i)"><v-icon color="white" small>mdi-minus</v-icon></span>
                        <span class="number" :id="`age${i + childrenNumber5 + childrenNumber4 + childrenNumber3 + childrenNumber2 + childrenNumber1}`">1</span>
                        <span class="plus" @click="increase('age', n, i)"><v-icon color="white" small>mdi-plus</v-icon></span>
                      </div>
                    </div>
                  </div>
                </div>
                <v-card-actions background-color="white">
                    <v-spacer></v-spacer>
                    <v-btn text @click="menu = false" color="error">Cancel</v-btn>
                    <v-btn color="success" text @click="save">Done</v-btn>
                </v-card-actions>
              </v-card-text>
            </v-card>
          </v-menu>
        </v-col>
        <v-col cols="12" md="6" class="py-0 mb-1" style="height: 70px">
          <v-autocomplete
          v-model="select"
          :items="items"
          :loading="loading"
          :search-input.sync="search"
          hide-no-data
          return-object
          outlined
          no-filter
          single-line
          :menu-props="{ auto: false, maxWidth: 'auto', overflowY: true }"
          item-text="cityName"
          item-value="code"
          prepend-inner-icon="mdi-map-marker"
          color="primary"
          :rules="$store.state.hotelSearch.destination !== null ? [true] : [v => !!v || 'Item is required']"
          class="mr-1"
          height="52"
          auto-select-first
          placeholder="Destination"
          id="hotel-autocomplete"
          >
            <template v-slot:item="data">
              <v-list-item-icon>
                <v-icon>{{data.item.isHotel ? 'mdi-bed' : 'mdi-map-marker'}}</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title v-html="data.item.name"></v-list-item-title>
              </v-list-item-content>
            </template>
            <template v-slot:selection="data">
              <span class="text-truncate">{{data.item.name}}</span>
            </template>
          </v-autocomplete>
        </v-col>
        <v-col cols="12" md="6">
          <picker type="hotels"></picker>
        </v-col>
      </v-row>
      <v-row no-gutters cols="12">
        <v-col cols="12" sm="6" md="3" class="px-1">
          <v-autocomplete
          v-model="guestNationality"
          :items="countries"
          item-text="name"
          item-value="code"
          placeholder="Nationality"
          outlined
          :rules="[v => !!v || 'Please choose your country!']"
          prepend-inner-icon="mdi-flag"
          hide-no-data
          color="primary"
          autocomplete="off"
          dense
          id="nationality-autocomplete"
          height="52"
          return-object
          ></v-autocomplete>
        </v-col>
        <v-col cols="12" sm="6" md="3" class="px-1">
          <v-select
          id="hotel-rating"
          v-model="starRating"
          :items="starRatingOptions"
          item-text="text"
          item-value="value"
          placeholder="Stars Rating"
          outlined
          dense
          prepend-inner-icon="mdi-star"
          color="primary"
          height="52"
          >
          </v-select>
        </v-col>
        <v-col cols="12" md="6" class="text-right">
          <v-btn
          type="submit"
          @click="submit"
          tile
          color="secondary"
          height="52"
          dark
          class="white--text rounded-lg px-10"
          id="hotel-search-submit"
          elevation="0"
          >
          Search
          </v-btn>
        </v-col>
      </v-row>
    </v-form>
</template>

<script>
import { getCities, getCitiesByCode, headersNoAuth } from '../links'
import picker from '../components/datePicker.vue'
import { countries } from '../contants'

export default {
  components: {
    picker
  },
  data () {
    return {
      loading: false,
      alert: false,
      color: '',
      text: '',
      select: null,
      search: null,
      items: [],
      menu: false,
      label: '1 room, 1 guest',
      valid: true,
      roomsNum: 1,
      childrenNum: [],
      childrenNumber1: 0,
      childrenNumber2: 0,
      childrenNumber3: 0,
      childrenNumber4: 0,
      childrenNumber5: 0,
      childrenNumber6: 0,
      number: 1,
      adults: [1],
      children: [0],
      childrenAges: [],
      guests: [],
      guestNationality: { code: 'US', name: 'United States' },
      starRating: '',
      starRatingOptions: [
        { text: 'All', value: 'All' },
        { text: 'One Star Or Less ', value: 'OneStarOrLess ' },
        { text: 'Two Stars Or Less', value: 'TwoStarOrLess' },
        { text: 'Three Stars Or Less', value: 'ThreeStarOrLess' },
        { text: 'Four Stars Or Less', value: 'FourStarOrLess' },
        { text: 'Five Stars Or Less', value: 'FiveStarOrLess' },
        { text: 'One Star Or More', value: 'OneStarOrMore' },
        { text: 'Two Stars Or More', value: 'TwoStarOrMore' },
        { text: 'Three Stars Or More', value: 'ThreeStarOrMore' },
        { text: 'Four Stars Or More', value: 'FourStarOrMore' },
        { text: 'Five Stars Or More', value: 'FiveStarOrMore' }
      ],
      firstRequest: true
    }
  },
  computed: {
    countries () {
      return countries
    }
  },
  watch: {
    search (val) {
      val && val !== this.select && this.querySelections(val)
    },
    select (val) {
      this.$store.dispatch('setHotelDestination', val)
    },
    roomsNum (val) {
      this.number = Number(val)
    },
    guestNationality (val) {
      if (val) this.$store.dispatch('setCountryCode', val.code)
      else this.$store.dispatch('setCountryCode', 'US')
    },
    starRating (val) {
      this.$store.dispatch('setStarRating', val)
    }
  },
  methods: {
    increase (type, n, i) {
      switch (type) {
        case 'rooms':
          if (this.roomsNum > 5) this.roomsNum = 6
          else this.roomsNum += 1
          document.getElementById('room').innerText = this.roomsNum
          this.adults.push(1)
          this.children.push(0)
          break
        case 'adults':
          if (this.adults[n - 1] > 5) this.adults[n - 1] = 6
          else {
            this.adults[n - 1] === undefined ? this.adults[n - 1] = 2 : this.adults[n - 1] = this.adults[n - 1] + 1
          }
          document.getElementById(`adult${n}`).innerText = this.adults[n - 1]
          break
        case 'children':
          if (this.children[n - 1] > 3) this.children[n - 1] = 4
          else {
            this.children[n - 1] === undefined ? this.children[n - 1] = 1 : this.children[n - 1] = this.children[n - 1] + 1
          }
          document.getElementById(`child${n}`).innerText = String(this.children[n - 1])
          this.childrenNum[n - 1] = this.children[n - 1]
          if (this.childrenNum[n - 1] > 0) {
            document.getElementById(`ages${n}`).style.display = 'flex'
            switch (n) {
              case 1:
                this.childrenNumber1 = this.childrenNum[n - 1]
                this.childrenAges.push(1)
                break
              case 2:
                this.childrenNumber2 = this.childrenNum[n - 1]
                this.childrenAges.push(1)
                break
              case 3:
                this.childrenNumber3 = this.childrenNum[n - 1]
                this.childrenAges.push(1)
                break
              case 4:
                this.childrenNumber4 = this.childrenNum[n - 1]
                this.childrenAges.push(1)
                break
              case 5:
                this.childrenNumber5 = this.childrenNum[n - 1]
                this.childrenAges.push(1)
                break
              case 6:
                this.childrenNumber6 = this.childrenNum[n - 1]
                this.childrenAges.push(1)
                break
            }
          } else document.getElementById(`ages${n}`).style.display = 'none'
          break
        case 'age':
          switch (n) {
            case 1:
              if (this.childrenAges[i - 1] > 11) this.childrenAges[i - 1] = 12
              else {
                this.childrenAges[i - 1] === undefined ? this.childrenAges[i - 1] = 2 : this.childrenAges[i - 1] = this.childrenAges[i - 1] + 1
              }
              document.getElementById(`age${i}`).innerText = this.childrenAges[i - 1]
              break
            case 2:
              if (this.childrenAges[(i - 1) + this.childrenNumber1] > 11) this.childrenAges[(i - 1) + this.childrenNumber1] = 12
              else {
                this.childrenAges[(i - 1) + this.childrenNumber1] === undefined ? this.childrenAges[(i - 1) + this.childrenNumber1] = 2 : this.childrenAges[(i - 1) + this.childrenNumber1] = this.childrenAges[(i - 1) + this.childrenNumber1] + 1
              }
              document.getElementById(`age${i + this.childrenNumber1}`).innerText = this.childrenAges[(i - 1) + this.childrenNumber1]
              break
            case 3:
              if (this.childrenAges[(i - 1) + this.childrenNumber2 + this.childrenNumber1] > 11) this.childrenAges[(i - 1) + this.childrenNumber2 + this.childrenNumber1] = 12
              else {
                this.childrenAges[(i - 1) + this.childrenNumber2 + this.childrenNumber1] === undefined ? this.childrenAges[(i - 1) + this.childrenNumber2 + this.childrenNumber1] = 2 : this.childrenAges[(i - 1) + this.childrenNumber2 + this.childrenNumber1] = this.childrenAges[(i - 1) + this.childrenNumber2 + this.childrenNumber1] + 1
              }
              document.getElementById(`age${i + this.childrenNumber2 + this.childrenNumber1}`).innerText = this.childrenAges[(i - 1) + this.childrenNumber2 + this.childrenNumber1]
              break
            case 4:
              if (this.childrenAges[(i - 1) + this.childrenNumber3 + this.childrenNumber2 + this.childrenNumber1] > 11) this.childrenAges[(i - 1) + this.childrenNumber3 + this.childrenNumber2 + this.childrenNumber1] = 12
              else {
                this.childrenAges[(i - 1) + this.childrenNumber3 + this.childrenNumber2 + this.childrenNumber1] === undefined ? this.childrenAges[(i - 1) + this.childrenNumber3 + this.childrenNumber2 + this.childrenNumber1] = 2 : this.childrenAges[(i - 1) + this.childrenNumber3 + this.childrenNumber2 + this.childrenNumber1] = this.childrenAges[(i - 1) + this.childrenNumber3 + this.childrenNumber2 + this.childrenNumber1] + 1
              }
              document.getElementById(`age${i + this.childrenNumber3 + this.childrenNumber2 + this.childrenNumber1}`).innerText = this.childrenAges[(i - 1) + this.childrenNumber3 + this.childrenNumber2 + this.childrenNumber1]
              break
            case 5:
              if (this.childrenAges[(i - 1) + this.childrenNumber4 + this.childrenNumber3 + this.childrenNumber2 + this.childrenNumber1] > 11) this.childrenAges[(i - 1) + this.childrenNumber4 + this.childrenNumber3 + this.childrenNumber2 + this.childrenNumber1] = 12
              else {
                this.childrenAges[(i - 1) + this.childrenNumber4 + this.childrenNumber3 + this.childrenNumber2 + this.childrenNumber1] === undefined ? this.childrenAges[(i - 1) + this.childrenNumber4 + this.childrenNumber3 + this.childrenNumber2 + this.childrenNumber1] = 2 : this.childrenAges[(i - 1) + this.childrenNumber4 + this.childrenNumber3 + this.childrenNumber2 + this.childrenNumber1] = this.childrenAges[(i - 1) + this.childrenNumber4 + this.childrenNumber3 + this.childrenNumber2 + this.childrenNumber1] + 1
              }
              document.getElementById(`age${i + this.childrenNumber4 + this.childrenNumber3 + this.childrenNumber2 + this.childrenNumber1}`).innerText = this.childrenAges[(i - 1) + this.childrenNumber4 + this.childrenNumber3 + this.childrenNumber2 + this.childrenNumber1]
              break
            case 6:
              if (this.childrenAges[(i - 1) + this.childrenNumber5 + this.childrenNumber4 + this.childrenNumber3 + this.childrenNumber2 + this.childrenNumber1] > 11) this.childrenAges[(i - 1) + this.childrenNumber5 + this.childrenNumber4 + this.childrenNumber3 + this.childrenNumber2 + this.childrenNumber1] = 12
              else {
                this.childrenAges[(i - 1) + this.childrenNumber5 + this.childrenNumber4 + this.childrenNumber3 + this.childrenNumber2 + this.childrenNumber1] === undefined ? this.childrenAges[(i - 1) + this.childrenNumber5 + this.childrenNumber4 + this.childrenNumber3 + this.childrenNumber2 + this.childrenNumber1] = 2 : this.childrenAges[(i - 1) + this.childrenNumber5 + this.childrenNumber4 + this.childrenNumber3 + this.childrenNumber2 + this.childrenNumber1] = this.childrenAges[(i - 1) + this.childrenNumber5 + this.childrenNumber4 + this.childrenNumber3 + this.childrenNumber2 + this.childrenNumber1] + 1
              }
              document.getElementById(`age${i + this.childrenNumber5 + this.childrenNumber4 + this.childrenNumber3 + this.childrenNumber2 + this.childrenNumber1}`).innerText = this.childrenAges[(i - 1) + this.childrenNumber5 + this.childrenNumber4 + this.childrenNumber3 + this.childrenNumber2 + this.childrenNumber1]
              break
          }
          break
        default:
          break
      }
    },
    decrease (type, n, i) {
      switch (type) {
        case 'rooms':
          if (this.roomsNum < 2) this.roomsNum = 1
          else this.roomsNum -= 1
          document.getElementById('room').innerText = this.roomsNum
          for (let i = 0; i < this.children[this.children.length - 1]; i++) {
            this.childrenAges.pop()
          }
          this.adults.pop()
          this.children.pop()
          break
        case 'adults':
          if (this.adults[n - 1] < 2) this.adults[n - 1] = 1
          else {
            this.adults[n - 1] === undefined ? this.adults[n - 1] = 1 : this.adults[n - 1] = this.adults[n - 1] - 1
          }
          document.getElementById(`adult${n}`).innerText = this.adults[n - 1]
          break
        case 'children':
          if (this.children[n - 1] < 1) this.children[n - 1] = 0
          else {
            this.children[n - 1] === undefined ? this.children[n - 1] = 0 : this.children[n - 1] = this.children[n - 1] - 1
          }
          document.getElementById(`child${n}`).innerText = this.children[n - 1]
          this.childrenNum[n - 1] = this.children[n - 1]
          if (this.childrenNum[n - 1] > 0) {
            document.getElementById(`ages${n}`).style.display = 'flex'
            switch (n) {
              case 1:
                this.childrenNumber1 = this.childrenNum[n - 1]
                this.childrenAges.splice((n - 1), 1)
                break
              case 2:
                this.childrenNumber2 = this.childrenNum[n - 1]
                this.childrenAges.splice((n - 1), 1)
                break
              case 3:
                this.childrenNumber3 = this.childrenNum[n - 1]
                this.childrenAges.splice((n - 1), 1)
                break
              case 4:
                this.childrenNumber4 = this.childrenNum[n - 1]
                this.childrenAges.splice((n - 1), 1)
                break
              case 5:
                this.childrenNumber5 = this.childrenNum[n - 1]
                this.childrenAges.splice((n - 1), 1)
                break
              case 6:
                this.childrenNumber6 = this.childrenNum[n - 1]
                this.childrenAges.splice((n - 1), 1)
                break
            }
          } else document.getElementById(`ages${n}`).style.display = 'none'
          break
        case 'age':
          switch (n) {
            case 1:
              if (this.childrenAges[i - 1] < 2) this.childrenAges[i - 1] = 1
              else {
                this.childrenAges[i - 1] === undefined ? this.childrenAges[i - 1] = 1 : this.childrenAges[i - 1] = this.childrenAges[i - 1] - 1
              }
              document.getElementById(`age${i}`).innerText = this.childrenAges[i - 1]
              break
            case 2:
              if (this.childrenAges[(i - 1) + this.childrenNumber1] < 2) this.childrenAges[(i - 1) + this.childrenNumber1] = 1
              else {
                this.childrenAges[(i - 1) + this.childrenNumber1] === undefined ? this.childrenAges[(i - 1) + this.childrenNumber1] = 1 : this.childrenAges[(i - 1) + this.childrenNumber1] = this.childrenAges[(i - 1) + this.childrenNumber1] - 1
              }
              document.getElementById(`age${i + this.childrenNumber1}`).innerText = this.childrenAges[(i - 1) + this.childrenNumber1]
              break
            case 3:
              if (this.childrenAges[(i - 1) + this.childrenNumber2 + this.childrenNumber1] < 2) this.childrenAges[(i - 1) + this.childrenNumber2 + this.childrenNumber1] = 1
              else {
                this.childrenAges[(i - 1) + this.childrenNumber2 + this.childrenNumber1] === undefined ? this.childrenAges[(i - 1) + this.childrenNumber2 + this.childrenNumber1] = 1 : this.childrenAges[(i - 1) + this.childrenNumber2 + this.childrenNumber1] = this.childrenAges[(i - 1) + this.childrenNumber2 + this.childrenNumber1] - 1
              }
              document.getElementById(`age${i + this.childrenNumber2 + this.childrenNumber1}`).innerText = this.childrenAges[(i - 1) + this.childrenNumber2 + this.childrenNumber1]
              break
            case 4:
              if (this.childrenAges[(i - 1) + this.childrenNumber3 + this.childrenNumber2 + this.childrenNumber1] < 2) this.childrenAges[(i - 1) + this.childrenNumber3 + this.childrenNumber2 + this.childrenNumber1] = 1
              else {
                this.childrenAges[(i - 1) + this.childrenNumber3 + this.childrenNumber2 + this.childrenNumber1] === undefined ? this.childrenAges[(i - 1) + this.childrenNumber3 + this.childrenNumber2 + this.childrenNumber1] = 1 : this.childrenAges[(i - 1) + this.childrenNumber3 + this.childrenNumber2 + this.childrenNumber1] = this.childrenAges[(i - 1) + this.childrenNumber3 + this.childrenNumber2 + this.childrenNumber1] - 1
              }
              document.getElementById(`age${i + this.childrenNumber3 + this.childrenNumber2 + this.childrenNumber1}`).innerText = this.childrenAges[(i - 1) + this.childrenNumber3 + this.childrenNumber2 + this.childrenNumber1]
              break
            case 5:
              if (this.childrenAges[(i - 1) + this.childrenNumber4 + this.childrenNumber3 + this.childrenNumber2 + this.childrenNumber1] < 2) this.childrenAges[(i - 1) + this.childrenNumber4 + this.childrenNumber3 + this.childrenNumber2 + this.childrenNumber1] = 1
              else {
                this.childrenAges[(i - 1) + this.childrenNumber4 + this.childrenNumber3 + this.childrenNumber2 + this.childrenNumber1] === undefined ? this.childrenAges[(i - 1) + this.childrenNumber4 + this.childrenNumber3 + this.childrenNumber2 + this.childrenNumber1] = 1 : this.childrenAges[(i - 1) + this.childrenNumber4 + this.childrenNumber3 + this.childrenNumber2 + this.childrenNumber1] = this.childrenAges[(i - 1) + this.childrenNumber4 + this.childrenNumber3 + this.childrenNumber2 + this.childrenNumber1] - 1
              }
              document.getElementById(`age${i + this.childrenNumber4 + this.childrenNumber3 + this.childrenNumber2 + this.childrenNumber1}`).innerText = this.childrenAges[(i - 1) + this.childrenNumber4 + this.childrenNumber3 + this.childrenNumber2 + this.childrenNumber1]
              break
            case 6:
              if (this.childrenAges[(i - 1) + this.childrenNumber5 + this.childrenNumber4 + this.childrenNumber3 + this.childrenNumber2 + this.childrenNumber1] < 2) this.childrenAges[(i - 1) + this.childrenNumber5 + this.childrenNumber4 + this.childrenNumber3 + this.childrenNumber2 + this.childrenNumber1] = 1
              else {
                this.childrenAges[(i - 1) + this.childrenNumber5 + this.childrenNumber4 + this.childrenNumber3 + this.childrenNumber2 + this.childrenNumber1] === undefined ? this.childrenAges[(i - 1) + this.childrenNumber5 + this.childrenNumber4 + this.childrenNumber3 + this.childrenNumber2 + this.childrenNumber1] = 1 : this.childrenAges[(i - 1) + this.childrenNumber5 + this.childrenNumber4 + this.childrenNumber3 + this.childrenNumber2 + this.childrenNumber1] = this.childrenAges[(i - 1) + this.childrenNumber5 + this.childrenNumber4 + this.childrenNumber3 + this.childrenNumber2 + this.childrenNumber1] - 1
              }
              document.getElementById(`age${i + this.childrenNumber5 + this.childrenNumber4 + this.childrenNumber3 + this.childrenNumber2 + this.childrenNumber1}`).innerText = this.childrenAges[(i - 1) + this.childrenNumber5 + this.childrenNumber4 + this.childrenNumber3 + this.childrenNumber2 + this.childrenNumber1]
              break
          }
          break
        default:
          break
      }
    },
    querySelections (v) {
      this.loading = true
      if (this.firstRequest) {
        if (v) {
          this.$http.get(getCitiesByCode(v), { headers: headersNoAuth() }).then(response => response.json()).then(response => {
            this.items.push(response)
            this.select = this.items[0]
            this.firstRequest = false
          }).then(() => (this.loading = false))
        } else {
          this.items = []
          this.select = null
        }
      } else {
        this.$http.get(getCities(v), { headers: headersNoAuth() }).then(response => response.json()).then(response => {
          this.items = response
          if (response.length === 1) this.select = response[0]
        }).then(() => (this.loading = false))
      }
    },
    save () {
      this.guests = []
      for (let i = 0; i < this.adults.length; i++) {
        this.adults[i] = this.adults[i] ? this.adults[i] : 1
      }
      for (let i = 0; i < this.children.length; i++) {
        this.children[i] = this.children[i] ? this.children[i] : 0
      }
      for (let i = 0; i < this.childrenAges.length; i++) {
        this.childrenAges[i] = this.childrenAges[i] ? this.childrenAges[i] : 1
      }
      const childrenAges = [...this.childrenAges]
      for (let i = 0; i < this.roomsNum; i++) {
        const roomGuest = {}
        roomGuest.adults = this.adults[i] ? this.adults[i] : 1
        roomGuest.children = this.children[i] ? this.children[i] : 0
        const roomChildrenAges = []
        for (let n = 0; n < this.children[i]; n++) {
          roomChildrenAges.push(childrenAges[n])
        }
        roomGuest.childrenAges = roomChildrenAges
        this.guests.push(roomGuest)
        childrenAges.splice(0, this.children[i])
      }
      let adults = 0
      let children = 0
      for (let i = 0; i < this.guests.length; i++) {
        adults = adults + this.guests[i].adults
        children = children + this.guests[i].children
      }
      this.label = `${this.guests.length} rooms, ${adults + children} guests`
      this.$store.dispatch('setHotelGuestsInfo', this.guests)
      this.menu = false
    },
    submit () {
      this.$refs.form.validate()
      if (this.valid) {
        const hotelSearch = this.$store.state.hotelSearch
        // const store = this.$store.state
        if (new Date(hotelSearch.checkInDate).getTime() > new Date().getTime()) {
          if (new Date(hotelSearch.checkInDate).getTime() <= new Date(hotelSearch.checkOutDate).getTime()) {
            let term = `checkIn=${hotelSearch.checkInDate}&checkOut=${hotelSearch.checkOutDate}&name=${hotelSearch.destination.name}&code=${hotelSearch.destination.code}&isHotel=${hotelSearch.destination.isHotel}&guestNationality=${this.guestNationality.code}&starRating=${this.starRating}&numberOfRooms=${hotelSearch.guestsInfo.length}`
            for (let i = 0; i < hotelSearch.guestsInfo.length; i++) {
              term = term + `&roomGuests[${i + 1}][adults]=${hotelSearch.guestsInfo[i].adults}`
              if (hotelSearch.guestsInfo[i].children > 0) {
                term = term + `&roomGuests[${i + 1}][children]=${hotelSearch.guestsInfo[i].children}`
                for (let n = 0; n < hotelSearch.guestsInfo[i].children; n++) {
                  term = term + `&roomGuests[${i + 1}][childAge][]=${hotelSearch.guestsInfo[i].childrenAges[n]}`
                }
              }
            }
            this.$store.dispatch('setHotelTimeOut', false)
            if (this.$store.state.hotelSearchTimeOutFunction !== null) this.$store.dispatch('clearHotelSearchTimeoutFunction')
            if (this.$store.state.hotelTimeOutFunction) this.$store.dispatch('clearHotelTimeoutFunction')
            this.$store.dispatch('removeHotelsResults')
            if (this.$route.name !== 'hotelsResults') {
              this.$router.push({ name: 'hotelsResults', params: { term: term } })
            } else {
              console.log('gotcha', term)
              this.$emit('addHotels', term)
            }
          } else {
            this.alert = true
            this.color = 'error'
            this.text = 'Please checkout date after the checkin date'
          }
        } else {
          this.alert = true
          this.color = 'error'
          this.text = 'Please choose checkin date after today'
        }
      } else {
        this.alert = true
        this.color = 'error'
        this.text = 'Please fill all fields'
      }
    }
  },
  created () {
    this.guestNationality = countries.find(item => item.code === this.$store.state.countryCode)
    this.starRating = this.$store.state.starRating ? this.$store.state.starRating : 'All'
    this.$store.dispatch('clearHotelGuests')
    this.guests = this.$store.state.hotelSearch.guestsInfo
    this.roomsNum = this.guests.length
    let adults = 0
    let children = 0
    for (let i = 0; i < this.roomsNum; i++) {
      this.adults.push(this.guests[i].adults)
      adults += this.guests[i].adults
      this.children.push(this.guests[i].children)
      children += this.guests[i].children
      if (this.guests[i].childrenAges.length > 0) {
        for (let x = 0; x < this.guests[i].childrenAges.length; x++) {
          this.childrenAges.push(this.guests[i].childrenAges[x])
        }
      }
    }
    this.label = `${this.guests.length} rooms, ${adults + children} guests`
  },
  mounted () {
    if (this.$store.state.hotelSearch.destination) {
      this.search = this.$store.state.hotelSearch.destination.code.toString()
    } else {
      this.firstRequest = false
    }
  }
}
</script>

<style>
  .inputs, .rooms, .adults, .children, .ages, .age {
    display: flex;
  }
  .rooms, .adults, .children, .age {
    margin-right: 2px;
    position: relative;
  }
  .room-text {
    width: 90px;
  }
  .inputs {
    margin: 18px auto;
    position: relative;
    max-width: 276px;
    flex-wrap: wrap;
  }
  .ages {
    direction: rtl;
    width: 100%;
    flex-wrap: wrap;
    display: none;
  }
  .age {
    /* position: absolute;
    bottom: -20px; */
    margin-top: 2px;
    margin-left: 5px;
    width: 44px;
  }
  .label {
    position: absolute;
    top: -18px;
  }
  .minus, .plus {
    background-color: #DF731C;
    width: 30px;
    text-align: center;
    transition: 0.2s linear;
    color: white;
  }
  .minus:hover, .plus:hover {
    cursor: pointer;
    background-color: #ff8522;
  }
  .number {
    width: 30px;
    text-align: center;
    color: #222;
    background-color: #eee;
  }
</style>
