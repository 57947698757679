<template>
    <div>
        <v-form
        ref="form"
        v-model="valid"
        v-on:submit.prevent
        >
            <v-snackbar
            :color="color"
            :timeout="60000"
            v-model="alert"
            top
            id="snackbar"
            >
              <v-row>
                {{text}}
                <v-spacer></v-spacer>
                <v-icon color="white" @click="alert = false">mdi-close</v-icon>
              </v-row>
            </v-snackbar>
            <v-row no-gutters>
              <v-col cols="12" md="6">
                <v-row no-gutters>
                  <v-col cols="12" md="6">
                    <city type="carPickup" />
                  </v-col>
                  <v-col cols="12" md="6">
                    <city type="carDrop"/>
                  </v-col>
                </v-row>
              </v-col>

              <v-col cols="12" md="6" class="px-1">
                <date-picker
                :disabled-date="disabledDates"
                v-model="date"
                type="datetime"
                range
                placeholder="Rental Time"
                format="MMM DD YYYY hh:mm a"
                value-type="YYYY-MM-DD hh:mm a"
                editable
                range-separator=" | "
                :time-picker-options="{
                  start: '00:00',
                  step: '00:30',
                  end: '23:30',
                }"
                :show-time-panel="showTimeRangePanel"
                @close="handleRangeClose"
                >
                  <template v-slot:icon-calendar>
                    <v-icon>mdi-calendar</v-icon>
                  </template>
                  <template v-slot:icon-clear>
                    <v-icon>mdi-close</v-icon>
                  </template>
                  <template v-slot:footer>
                    <button class="mx-btn mx-btn-text" @click="toggleTimeRangePanel">
                      {{ showTimeRangePanel ? 'select date' : 'select time' }}
                    </button>
                  </template>
                </date-picker>
              </v-col>
            </v-row>

            <v-row no-gutters justify="end">
              <v-btn
              type="submit"
              @click="submit"
              tile
              color="secondary"
              height="52"
              dark
              class="px-10 rounded-lg mx-1"
              id="cars-search-submit"
              >
              Search
              </v-btn>
            </v-row>
        </v-form>
    </div>
</template>

<script>
import city from './citiesAutocomplete.vue'
// import datePicker from './datePicker.vue'
// import timePicker from './timePicker.vue'

export default {
  components: {
    // datePicker,
    // timePicker,
    city
  },
  data () {
    return {
      valid: true,
      color: '',
      alert: false,
      text: '',
      dropOff: false,
      date: [],
      showTimeRangePanel: false
    }
  },
  watch: {
    date (val) {
      const pickupTime = this.converTimeTo24(val[0].split(' ')[1] + ' ' + val[0].split(' ')[2])
      const dropTime = this.converTimeTo24(val[1].split(' ')[1] + ' ' + val[1].split(' ')[2])
      this.$store.dispatch('setDates', { type: 'pickup', data: val[0].split(' ')[0] })
      this.$store.dispatch('setDates', { type: 'drop', data: val[1].split(' ')[0] })
      this.$store.dispatch('setCarTimes', { type: 'Pickup Time', data: pickupTime })
      this.$store.dispatch('setCarTimes', { type: 'Drop Time', data: dropTime })
    }
  },
  methods: {
    disabledDates (date) {
      const today = new Date()
      return date < today || date > new Date(today.setFullYear(today.getFullYear() + 1))
    },
    toggleTimeRangePanel () {
      this.showTimeRangePanel = !this.showTimeRangePanel
    },
    handleRangeClose () {
      this.showTimeRangePanel = false
    },
    converTimeTo24 (time) {
      let hours = Number(time.match(/^(\d+)/)[1])
      const minutes = Number(time.match(/:(\d+)/)[1])
      const AMPM = time.match(/\s(.*)$/)[1]
      if (AMPM === 'pm' && hours < 12) hours = hours + 12
      if (AMPM === 'am' && hours === 12) hours = hours - 12
      let sHours = hours.toString()
      let sMinutes = minutes.toString()
      if (hours < 10) sHours = '0' + sHours
      if (minutes < 10) sMinutes = '0' + sMinutes
      return sHours + ':' + sMinutes
    },
    converTimeTo12 (time) {
      time = time.toString().match(/^([01]\d|2[0-3])(:)([0-5]\d)?$/) || [time]

      if (time.length > 1) {
        time = time.slice(1)
        time[5] = +time[0] < 12 ? ' am' : ' pm'
        time[0] = +time[0] % 12 || 12
      }
      if (time[0] < 10) time[0] = '0' + time[0]
      return time.join('')
    },
    submit () {
      this.$refs.form.validate()
      if (this.valid) {
        const store = this.$store.state.carInfo
        if (new Date(store.pickup).getTime() > new Date().getTime()) {
          if (new Date(store.pickup).getTime() <= new Date(store.drop).getTime()) {
            let term = `pickUpDate=${store.pickup}&pickUpTime=${store.pickupTime}&returnDate=${store.drop}&returnTime=${store.dropTime}&pickUpLocation=${store.pickupLocation.Code}&sortOrder=ASC`
            if (store.dropLocation) term += `&dropOffLocation=${store.dropLocation.Code}`
            this.$store.dispatch('setCarsTimeOut', false)
            this.$store.dispatch('removeCarsResults')
            if (this.$store.state.carsSearchTimeOutFunction !== null) this.$store.dispatch('clearCarsSearchTimeOutFunction')
            if (this.$store.state.carsTimeOutFunction) this.$store.dispatch('clearCarsTimeOutFunction')
            if (this.$route.name === 'carsResults') {
              this.$emit('addCars', term)
            } else this.$router.push({ name: 'carsResults', params: { term: term } })
          } else {
            this.alert = true
            this.color = 'error'
            this.text = 'Please drop date after the pickp date'
          }
        } else {
          this.alert = true
          this.color = 'error'
          this.text = 'Please choose pickup date after today date'
        }
      } else {
        this.alert = true
        this.color = 'warning'
        this.text = 'Please fill all fields'
      }
    }
  },
  created () {
    this.date = [
      this.$store.state.carInfo.pickup + ' ' + this.converTimeTo12(this.$store.state.carInfo.pickupTime),
      this.$store.state.carInfo.drop + ' ' + this.converTimeTo12(this.$store.state.carInfo.dropTime)
    ]
  }
}
</script>
