<template>
  <div class="background">
    <div class="landing-overlay">
        <v-container>
            <v-row
            align="center"
            class="mt-15"
            no-gutters
            >
                <h1 class="col-12 white--text font-weight-black text-h2">Let’s Explore The World</h1>
                <h3 class="col-6 white--text font-weight-regular text-h5 mb-5">Book the favorite place all over the world And start exploring</h3>
                <v-tabs
                v-show="$route.name === 'Home'"
                v-model="tabs"
                background-color="transparent"
                dark
                class="tabs"
                >
                    <v-tab :style="`background-color: ${primaryColor};`" id="tab-flights">
                        <span class="font-weight-regular white--text body-1">Flights</span>
                    </v-tab>
                    <v-tab :style="`background-color: ${primaryColor};`" id="tab-hotels">
                        <span class="font-weight-regular white--text body-1">Hotels</span>
                    </v-tab>
                    <v-tab :style="`background-color: ${primaryColor};`" id="tab-cars">
                        <span class="font-weight-regular white--text body-1">Cars</span>
                    </v-tab>
                </v-tabs>
                <v-card tile class="pa-5 booking-form-container" elevation="2">
                    <v-tabs-items v-model="tabs" style="background: transparent;" class="mt-2">
                        <v-tab-item>
                            <flightSearch :loading="loading" @addFlights="$emit('addFlights', $event)"></flightSearch>
                        </v-tab-item>
                        <v-tab-item>
                            <hotelSearch @addHotels="$emit('addHotels', $event)"></hotelSearch>
                        </v-tab-item>
                        <v-tab-item>
                            <carSearch/>
                        </v-tab-item>
                    </v-tabs-items>
                </v-card>
            </v-row>
        </v-container>
    </div>
    <img class="wave" src="../static/wave.png">
  </div>
</template>

<script>
import flightSearch from './flightSearch.vue'
import hotelSearch from './hotelSearch.vue'
import carSearch from './carSearch.vue'

export default {
  components: {
    flightSearch,
    hotelSearch,
    carSearch
  },
  props: ['loading'],
  data () {
    return {
      tabs: null
    }
  },
  computed: {
    primaryColor () {
      return process.env.VUE_APP_DEFAULT_PRIMARY_COLOR
    }
  },
  created () {
    switch (this.$route.name) {
      case 'Home':
        this.tabs = null
        break
      case 'flightResults':
        this.tabs = 0
        break
      case 'hotelsResults':
        this.tabs = 1
        break
      case 'carsResults':
        this.tabs = 2
        break
    }
  }
}
</script>

<style>
    .background {
        height: fit-content;
        background: linear-gradient(#325aaf8c, #325aaf8c), url('../static/bg.png');
        background-size: 100% 100%;
        background-position: center center;
        position: relative;
    }
    .wave {
        position: absolute;
        bottom: -1%;
        width: 100%;
    }
    .landing-overlay {
        height: auto;
    }
    .booking-form-container {
        z-index: 1;
        min-width: 100%;
        position: relative;
    }
    .tabs {
        margin-bottom: 2px;
        margin-top: 60px;
    }
    .v-tab {
        /* background-color: #4D608D !important; */
        width: 15%;
    }
    /* .v-tab.v-tab--active {
        background-color: #0082C9 !important;
    } */
</style>
